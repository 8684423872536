<template>
  <div class="file-upload-container">
    <div
      class="file-upload-button"
      :style="!selected || uploading ? 'pointer-events: none;' : ''"
      @click="upload"
    >
      Upload
    </div>
    <div v-if="uploading" class="file-uploading-indicator">
      <label>Uploading</label>
      <md-progress-bar
        class="bar_color"
        md-mode="indeterminate"
      ></md-progress-bar>
    </div>
    <span
      v-if="!uploading"
      class="file-upload-browse"
      @click="launchFilePicker"
      >{{ !selected ? "Choose files" : selected }}</span
    >
    <div
      v-if="!uploading && selected"
      class="close-button"
      @click="clear($event)"
    >
      <v-icon>close</v-icon>
    </div>
    <input
      type="file"
      ref="file"
      multiple="multiple"
      style="display: none"
      :accept="acceptedFileTypes"
      @change="onFileChange($event.target.files)"
    />
  </div>
</template>

<script>
import restAdapter from "../restAdapter";
import notification from "../notification";
import utils from "../utils";

export default {
  name: "FileUpload",
  props: ["inquiryId"],
  data: () => ({
    uploading: false,
    acceptedFileTypes: utils.acceptedFileTypes,
    files: [],
    selectedFilesTotalSize: 0,
    filesSumLimit: 50 * 1024 * 1024,
    selected: null,
  }),
  methods: {
    onFileChange(files) {
      let sum = this.selectedFilesTotalSize;
      !(sum > this.filesSumLimit) &&
        [...files].forEach((file) => {
          sum += file.size;
        });
      if (sum > this.filesSumLimit) {
        notification.error("Files size limit exceeded");
        return;
      }
      this.selectedFilesTotalSize = sum;
      this.files.push(...files);
      if (this.files.length) {
        this.selected = `${this.files.length} file(s)`;
      } else {
        this.selected = null;
      }
    },
    launchFilePicker() {
      this.$refs.file.value = "";
      this.$refs.file.click();
    },
    upload() {
      this.uploading = true;
      const formData = new FormData();

      if (!this.files.length) {
        this.uploading = false;
        return null;
      }

      if (this.inquiryId) {
        for (let i = 0; i < this.files.length; i++) {
          const file = this.files[i];
          formData.append("file[" + i + "]", file);
        }
        restAdapter
          .post("/api/inquiries/files_upload/" + this.inquiryId, formData)
          .then(() => {
            notification.success("File uploaded successfully");
            this.$emit("file-uploaded");
            this.uploading = false;
            this.clear();
          })
          .catch((error) => {
            notification.error(error.response.data.status);
            this.$emit("file-uploaded");
            this.uploading = false;
            this.clear();
          });
      }
    },
    clear(event) {
      if (event) event.stopPropagation();
      this.files = [];
      this.$refs.file.value = null;
      this.selected = null;
      this.selectedFilesTotalSize = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-upload-container {
  width: 90%;
  height: 40px;
  margin: auto;
  background: rgba(245, 238, 255, 0.47);
  border: 0.5px dashed $primaryDarkest;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.file-upload-button {
  z-index: 10;
  margin-left: 5px;
  width: 30%;
  background: $fileUpload;
  border: 0.5px solid $primaryDark;
  border-radius: 3px;
  color: $primaryDarkest;
  font-weight: 400;
  cursor: pointer;
}

.file-upload-button:hover {
  box-shadow: 0 0.2px 0.3px -1px $primaryDarkest,
    0 0.2px 0.3px 1px $primaryDarkest, 0 0.2px 0.3px 0.2px $primaryDarkest,
    0 0.2px 0.3px 0.2px $primaryDarkest;
}

.file-upload-browse {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  color: $primaryDarkest;
  font-weight: 600;
  cursor: pointer;
}

.file-uploading-indicator {
  width: auto;
  text-align: left;
  color: $primaryDarkest;
  font-weight: 500;
  margin: 5px;
}

.spinner-box {
  position: relative;
  text-align: left;
}

.md-button.md-raised:not([disabled]).md-primary {
  background-color: #582963 !important;
  border-color: #582963 !important;
}

/* .md-progress-bar.md-theme-default.md-indeterminate, .md-progress-bar.md-theme-default.md-query {
    background-color: #c6a8ec !important;
    background-color: var(--md-theme-default-primary-on-, #c6a8ec) !important;
} */

.bar_color::v-deep .md-progress-bar-track:after {
  background-color: #582963 !important;
}

.md-progress-bar.md-theme-default.md-indeterminate,
.md-progress-bar.md-theme-default.md-query {
  background-color: #c6a8ec !important;
  background-color: var(--md-theme-default-primary-on-, #c6a8ec) !important;
}

.bar_color::v-deep .md-progress-bar-fill:after {
  background-color: #582963 !important;
}

.fileUpload {
  border: 0;
  line-height: 2;
  padding: 0 5px;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  border-radius: 10px;
  background-color: rgb(53, 51, 51);
}

.fileUpload:hover {
  background-color: rgb(182, 175, 175);
}

.close-button {
  cursor: pointer;
}
</style>
